<template>
  <div>
    <v-progress-linear indeterminate v-if="$apollo.loading"></v-progress-linear>
    <Modal
        btn-text="Filter"
        title="Filter results"
        v-model="filterModal"
        v-if="$vuetify.breakpoint.smAndDown"
    >
      <template v-slot:button="{ on }">
        <v-card-actions>
          <v-spacer/>
          <v-btn text v-on="on">
            <v-icon>
              fa-filter
            </v-icon>
            Filter
          </v-btn>
          <v-spacer/>
        </v-card-actions>
      </template>
      <filters
          v-model="filters"
          :show="showFilters"
          @toggle="showFilters = !showFilters"
          :show-delivery-types="showDeliveryTypes"
      ></filters>
    </Modal>

    <v-container fluid>
      <v-row>
        <v-col md="3" xl="2" v-if="$vuetify.breakpoint.mdAndUp">
          <filters
            v-model="filters"
            :show="showFilters"
            @toggle="showFilters = !showFilters"
            :show-delivery-types="showDeliveryTypes"
          />
        </v-col>
        <v-col cols="12" md="9" xl="10">
          <v-row>
          <search-result
            v-for="product in products"
            @select="$router.push(`/product/${product.id}`)"
            :product="product"
            :key="product.id"
        />
            </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {products, basket} from '@/graphql'
import {mapState, mapGetters} from 'vuex'
import SearchResult from "@/components/cards/SearchResult";
import Modal from "@/components/modals/Modal";

export default {
  components: {SearchResult, Modal},
  props: ['search'],
  apollo: {
    basket,
    products: {
      query: products,
      variables() {
        return {
          startDatetime: this.getStartDatetime,
          endDatetime: this.getEndDatetime,
          ...this.filters
        };
      }
    }
  },
  computed: {
    ...mapState(['store', 'startDatetime', 'endDatetime',]),
    ...mapGetters(['getStartDatetime', 'getEndDatetime', 'getDarkMode']),
    showDeliveryTypes() {
      if (this.products) {
        const types = Array.from(new Set(this.products.map(product => product.hireType)))
        return types.length > 1
      }
      return false
    },
    breadcrumbs() {
      return [
        {
          text: this.store.name,
          href: `/`
        },
        {
          text: 'Products',
          disabled: true
        }
      ]
    }
  },
  watch: {
    basket(basket) {
      if (basket && basket.lineItems.length > 0) {
        this.filters.hireType = basket.lineItems[0].hireType.toLowerCase()
      }
    },
    filters: {
      handler() {
        this.$apollo.queries.products.refetch()
      },
      deep: true
    },
    search() {
      this.$apollo.queries.products.refetch()
    }
  },
  data: () => ({
    showFilters: true,
    filterModal: false,
    filters: {
      categories: [],
      sizes: [],
      genders: [],
      brands: [],
      hireType: null
    },
  })
}
</script>
